import { call, put, takeLatest, all } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { createReceiptService } from 'services/receipt';
import { getBillsService, sumPaymentService } from 'services/bill';
import { PAYMENT_INIT, PAYMENT_BILL_GETS, PAYMENT_BILL_SUM_PAYMENT_GETS, PAYMENT_RECEIPT_CREATE, PAYMENT_UNIT_GETS } from './payment.types';

function* initPaymentPage(action) {
  const { unitGroupId } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  try {
    const result = yield call(getBillsService, {
      params: {
        page: 1,
        limit: 20,
        isShowBillInProcess: true,
      },
      headers,
    });
    yield put({
      type: PAYMENT_BILL_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: PAYMENT_BILL_GETS.FAIL,
      payload: error,
    });
  }

  try {
    const resSumPayment = yield call(
      sumPaymentService,
      {
        headers,
      },
      PAYMENT_BILL_SUM_PAYMENT_GETS
    );
    yield put({
      type: PAYMENT_BILL_SUM_PAYMENT_GETS.SUCCESS,
      payload: resSumPayment,
    });
  } catch (error) {
    yield put({
      type: PAYMENT_BILL_SUM_PAYMENT_GETS.FAIL,
      payload: error,
    });
  }

  yield put({
    type: PAYMENT_INIT.SUCCESS,
    payload: {
      code: 200,
    },
  });
}

function* getSumPaymentInPagePayment(action) {
  const { unitGroupId, filterObject } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  try {
    const result = yield call(
      sumPaymentService,
      {
        params,
        headers,
      },
      PAYMENT_BILL_SUM_PAYMENT_GETS
    );
    yield put({
      type: PAYMENT_BILL_SUM_PAYMENT_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: PAYMENT_BILL_SUM_PAYMENT_GETS.FAIL,
      payload: error,
    });
  }
}

function* getBillByGroupTargetPagePayment(action) {
  const { unitGroupId, page, filterObject } = action.payload;
  const params = {
    page,
    limit: 20,
    ...filterObject,
    isShowBillInProcess: true,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  try {
    const result = yield call(getBillsService, { params, headers });
    yield put({
      type: PAYMENT_BILL_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: PAYMENT_BILL_GETS.FAIL,
      payload: error,
    });
  }
}

function* createReceiptInPagePayment(action) {
  const { receipt, unitGroupId } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  try {
    const result = yield call(createReceiptService, {
      body: receipt,
      headers,
    });
    yield put({
      type: PAYMENT_RECEIPT_CREATE.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: PAYMENT_RECEIPT_CREATE.FAIL,
      payload: error,
    });
  }

  yield put({
    type: PAYMENT_RECEIPT_CREATE.REFRESH,
  });
}

function* getUnits(action) {
  const { unitGroupId, name, page, limit } = action.payload;

  const params = {
    page,
    limit,
    unitName: name,
    isShowBillInProcess: true,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  try {
    const result = yield call(getBillsService, {
      params,
      headers,
    });
    yield put({
      type: PAYMENT_UNIT_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: PAYMENT_UNIT_GETS.FAIL,
      payload: error,
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export default function* watch() {
  yield all([
    takeLatest(PAYMENT_INIT.REQUEST, initPaymentPage),
    takeLatest(PAYMENT_BILL_GETS.REQUEST, getBillByGroupTargetPagePayment),
    takeLatest(PAYMENT_BILL_SUM_PAYMENT_GETS.REQUEST, getSumPaymentInPagePayment),
    takeLatest(PAYMENT_RECEIPT_CREATE.REQUEST, createReceiptInPagePayment),
    takeLatest(PAYMENT_UNIT_GETS.REQUEST, getUnits),
  ]);
}
