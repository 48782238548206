import {
  BUILDING,
  LOCK_DATA_FORM_HISTORY,
  LOCK_DATA_FORM_EMPLOYEES_FILTER,
  LOCK_DATA_FORM_UPDATE,
  LOCK_DATA_FORM_CREATE,
} from './building.types';

export const initState = {
  data: undefined,
  cities: [],
  districts: [],
  types: [],
  staffs: [],
  lockDataAt: undefined,
  lockDataHistory: {
    data: [],
    totalRecord: 0,
  },
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case BUILDING.GET_BY_ID.REQUEST:
    case BUILDING.GET_BY_ID.FAIL:
    case BUILDING.GET_BY_ID.REFRESH: {
      return {
        ...state,
        data: undefined,
      };
    }
    case BUILDING.GET_BY_ID.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case BUILDING.CITY_GETS.REQUEST:
    case BUILDING.CITY_GETS.FAIL:
    case BUILDING.CITY_GETS.REFRESH: {
      return {
        ...state,
        cities: [],
      };
    }
    case BUILDING.CITY_GETS.SUCCESS: {
      return {
        ...state,
        cities: action.payload.data,
      };
    }
    case BUILDING.DISTRICT_GETS.REQUEST:
    case BUILDING.DISTRICT_GETS.FAIL:
    case BUILDING.DISTRICT_GETS.REFRESH: {
      return {
        ...state,
        cities: [],
      };
    }
    case BUILDING.DISTRICT_GETS.SUCCESS: {
      return {
        ...state,
        districts: action.payload.data,
      };
    }
    case BUILDING.TYPE_GETS.REQUEST:
    case BUILDING.TYPE_GETS.FAIL:
    case BUILDING.TYPE_GETS.REFRESH: {
      return {
        ...state,
        types: [],
      };
    }
    case BUILDING.TYPE_GETS.SUCCESS: {
      return {
        ...state,
        types: action.payload.data,
      };
    }
    case LOCK_DATA_FORM_EMPLOYEES_FILTER.REQUEST:
    case LOCK_DATA_FORM_EMPLOYEES_FILTER.FAIL:
    case LOCK_DATA_FORM_EMPLOYEES_FILTER.REFRESH: {
      return {
        ...state,
        staffs: [],
      };
    }
    case LOCK_DATA_FORM_EMPLOYEES_FILTER.SUCCESS: {
      return {
        ...state,
        staffs: action.payload.data,
      };
    }
    case LOCK_DATA_FORM_HISTORY.REQUEST:
    case LOCK_DATA_FORM_HISTORY.FAIL:
    case LOCK_DATA_FORM_HISTORY.REFRESH: {
      return {
        ...state,
        lockDataHistory: {
          data: [],
          totalRecord: 0,
        },
      };
    }
    case LOCK_DATA_FORM_HISTORY.SUCCESS: {
      return {
        ...state,
        lockDataHistory: {
          data: action.payload.data,
          totalRecord: action.payload.totalRecord,
        },
      };
    }
    case LOCK_DATA_FORM_CREATE.REQUEST:
    case LOCK_DATA_FORM_CREATE.FAIL:
    case LOCK_DATA_FORM_CREATE.REFRESH:
    case LOCK_DATA_FORM_UPDATE.REQUEST:
    case LOCK_DATA_FORM_UPDATE.FAIL:
    case LOCK_DATA_FORM_UPDATE.REFRESH: {
      return {
        ...state,
        lockDataAt: undefined,
      };
    }
    case LOCK_DATA_FORM_CREATE.SUCCESS:
    case LOCK_DATA_FORM_UPDATE.SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          lockDataAt: action?.payload?.data?.lockDataAt,
        },
        lockDataAt: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
