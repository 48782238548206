import { useContext } from 'react';
import { Context as GlobalContext } from 'globalContext';
import { getApartmentTypesService } from 'services/apartment';

const useApartmentType = () => {
  const { apartmentTypes, setApartmentTypes } = useContext(GlobalContext);

  const getApartmentTypes = async () => {
    const response = await getApartmentTypesService();
    setApartmentTypes(response.data);
  };

  return { apartmentTypes, getApartmentTypes };
};

export default useApartmentType;
