import { API_TYPE_CALL_API, API_TYPE_DOWNLOAD_FILE, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const RECEIPT = `${SERVER}/receipt`;
const RECEIPT_CREATE_MANUAL = `${RECEIPT}/manual`;
const RECEIPT_UPDATE = (id) => `${RECEIPT}/${id}`;
const RECEIPT_CREATE = RECEIPT;
const RECEIPT_GETS = `${RECEIPT}/list`;
const RECEIPT_GET_BY_ID = RECEIPT;
const RECEIPT_EXPORT = `${RECEIPT}/export-data`;
const RECEIPT_CANCEL = RECEIPT;
const RECEIPT_SUM_TOTAL_PAYMENT = `${RECEIPT}/sum-payment`;

export const createReceiptService = async ({ body, headers, bodyType }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RECEIPT_CREATE,
      body,
      method: METHOD.POST,
      options: {
        headers,
        bodyType,
      },
    },
  });
  return response;
};

export const createReceiptManualService = async ({ body, headers }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RECEIPT_CREATE_MANUAL,
      body,
      method: METHOD.POST,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const updateReceiptService = async ({ idBuilding, receipt }) => {
  const { id, ...receiptValue } = receipt;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RECEIPT_UPDATE(id),
      body: {
        ...receiptValue,
      },
      method: METHOD.PUT,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getReceiptsService = async ({ idBuilding, filter, page, limit }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RECEIPT_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getReceiptByIdService = async ({ idBuilding, idReceipt }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${RECEIPT_GET_BY_ID}/${idReceipt}`,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        bodyType: 'html',
      },
    },
  });
  return response;
};

export const exportReceiptService = async ({ filter, idBuilding }) => {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: RECEIPT_EXPORT,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
        },
      },
    },
  });
  return response;
};

export const cancelReceiptService = async ({ idReceipt, reason, type, fundId, bills, isAllBill, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${RECEIPT_CANCEL}/${idReceipt}/cancel`,
      method: METHOD.POST,
      body: {
        reason,
        type,
        fundId,
        bills,
        isAllBill,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getSumTotalPaymentReceiptService = async ({ idBuilding, filter }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RECEIPT_SUM_TOTAL_PAYMENT,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
