import { useContext } from 'react';
import { Context as GlobalContext } from 'globalContext';
import { getFloorsService } from 'services/floor';

const useFloor = () => {
  const { floors, setFloors } = useContext(GlobalContext);

  const getFloors = async ({ idBuilding, filter, limit, page }) => {
    const response = await getFloorsService({ idBuilding, filter, limit, page });
    setFloors(response.data);
    return response;
  };

  return { floors, getFloors };
};

export default useFloor;
