import { language } from '../utils/language';
import convertArrToObj from '../utils/convertArrToObj';

export const INVOICE_NORMAL = 'NORMAL';
export const INVOICE_REMIND_DEBT = 'REMIND_DEBT';
export const INVOICE_REMIND_DEBT_FIRST = 'REMIND_DEBT_FIRST';
export const INVOICE_REMIND_DEBT_SECOND = 'REMIND_DEBT_SECOND';

export const INVOICE_BILLS_ALL = 'ALL';
export const INVOICE_BILLS_UNPAID = 'UNPAID';

export const INVOICE_EMAIL_NOT_STARTED = 'NOT_STARTED';
export const INVOICE_EMAIL_IN_PROGRESS = 'IN_PROGRESS';
export const INVOICE_EMAIL_COMPLETED = 'COMPLETED';
export const INVOICE_EMAIL_FAILED = 'FAILED';

export const INVOICE_EMAIL_STATUS = [
  {
    id: INVOICE_EMAIL_IN_PROGRESS,
    name: language.label.sending,
  },
  {
    id: INVOICE_EMAIL_COMPLETED,
    name: language.label.success,
  },
  {
    id: INVOICE_EMAIL_FAILED,
    name: language.label.failed,
  },
];

export const INVOICE_EMAIL_STATUS_BY_ID = convertArrToObj(INVOICE_EMAIL_STATUS, 'id');

export const INVOICE_TYPE_FILTERS = [
  { id: INVOICE_NORMAL, name: language.label.normal_invoice },
  { id: INVOICE_REMIND_DEBT_FIRST, name: language.remind_debt_first_times },
  { id: INVOICE_REMIND_DEBT_SECOND, name: language.remind_debt_second_times },
];
export const INVOICE_TYPES = [
  { id: INVOICE_NORMAL, name: language.label.normal_invoice },
  { id: INVOICE_REMIND_DEBT, name: language.label.debt_invoice },
];

export const INVOICE_REMIND_DEBT_TYPES = [
  { id: INVOICE_REMIND_DEBT_FIRST, name: language.remind_debt_first_times },
  { id: INVOICE_REMIND_DEBT_SECOND, name: language.remind_debt_second_times },
];

export const INVOICE_TYPE_ID = {
  [INVOICE_NORMAL]: language.label.normal_invoice,
  [INVOICE_REMIND_DEBT_FIRST]: language.remind_debt_first_times,
  [INVOICE_REMIND_DEBT_SECOND]: language.remind_debt_second_times,
};

export const INVOICE_BILLS_TYPE = [
  { id: INVOICE_BILLS_ALL, name: language.label.show_all_invoices_arising_in_the_month },
  { id: INVOICE_BILLS_UNPAID, name: language.label.show_all_outstanding_invoices_arising_in_the_month },
];

export const INVOICE_SEND_METHOD_EMAIL = 'EMAIL';
export const INVOICE_SEND_METHOD_PRINT = 'PRINT';

export const INVOICE_SEND_METHOD_OPTIONS = [
  {
    id: 'EMAIL',
    name: language.email,
  },
  {
    id: 'PRINT',
    name: language.print,
  },
];

export const INVOICE_SEND_METHOD_OBJ = convertArrToObj(INVOICE_SEND_METHOD_OPTIONS, 'id');
export const INVOICE_TYPE_OBJ = convertArrToObj(INVOICE_TYPE_FILTERS, 'id');
