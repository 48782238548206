import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getsAmenitiesService,
  getAmenitiesService,
  getsPriorityInAppSelectedService,
  addAmenitiesService,
  updateAmenitiesService,
} from 'services/amenities';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { AMENITIES } from './amenities.types';
import { convertAmenities } from './amenities.utils';

function* getsAmenities(action) {
  try {
    const { page, limit, filterObj, idBuilding } = action.payload;
    const result = yield call(getsAmenitiesService, {
      params: {
        page,
        limit,
        ...filterObj,
      },
      headers: {
        [X_UNIT_GROUP_ID]: idBuilding,
      },
    });
    yield put({
      type: AMENITIES.GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: AMENITIES.GETS.FAIL,
      payload: error,
    });
  }
}

function* getAmenities(action) {
  try {
    const { idAmenities, idBuilding } = action.payload;
    const result = yield call(
      getAmenitiesService,
      {
        params: {
          id: idAmenities,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
      AMENITIES.GET
    );
    yield put({
      type: AMENITIES.GET.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: AMENITIES.GET.FAIL,
      payload: error,
    });
  }
}

function* getPriorityInAppSelected(action) {
  try {
    const { idBuilding } = action.payload;
    const result = yield call(getsPriorityInAppSelectedService, {
      headers: {
        [X_UNIT_GROUP_ID]: idBuilding,
      },
    });
    yield put({
      type: AMENITIES.GET_PRIORITY_IN_APP_SELECTED.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: AMENITIES.GET_PRIORITY_IN_APP_SELECTED.FAIL,
      payload: error,
    });
  }
}

function* addAmenities(action) {
  try {
    const amenities = {
      ...action.payload,
    };

    const result = yield call(addAmenitiesService, {
      body: convertAmenities(amenities),
      headers: {
        [X_UNIT_GROUP_ID]: amenities.unitGroupId,
      },
    });
    yield put({
      type: AMENITIES.ADD.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: AMENITIES.ADD.FAIL,
      payload: error,
    });
  }
}

function* updateAmenities(action) {
  try {
    const amenities = {
      ...action.payload,
    };
    const result = yield call(updateAmenitiesService, {
      body: convertAmenities(amenities),
      headers: {
        [X_UNIT_GROUP_ID]: amenities.unitGroupId,
      },
    });
    yield put({
      type: AMENITIES.UPDATE.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: AMENITIES.UPDATE.FAIL,
      payload: error,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(AMENITIES.GETS.REQUEST, getsAmenities),
    takeLatest(AMENITIES.GET.REQUEST, getAmenities),
    takeLatest(AMENITIES.GET_PRIORITY_IN_APP_SELECTED.REQUEST, getPriorityInAppSelected),
    takeLatest(AMENITIES.ADD.REQUEST, addAmenities),
    takeLatest(AMENITIES.UPDATE.REQUEST, updateAmenities),
  ]);
}
