import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  getBuildingByIdService,
  updateBuildingService,
  getBuildingTypesService,
  createLockDataService,
  updateLockDataService,
  getHistoryLockDataService,
  addBuildingService,
} from 'services/building';
import { getCitiesService, getDistrictsService } from 'services/base';
import { getEmployeesByPageService } from 'services/employee';
import {
  BUILDING,
  LOCK_DATA_FORM_CREATE,
  LOCK_DATA_FORM_HISTORY,
  LOCK_DATA_FORM_EMPLOYEES_FILTER,
  LOCK_DATA_FORM_UPDATE,
} from './building.types';

function* getBuildingById(action) {
  const { idBuilding, refreshState } = action.payload;

  try {
    const result = yield call(getBuildingByIdService, { idBuilding });
    yield put({
      type: BUILDING.GET_BY_ID.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: BUILDING.GET_BY_ID.FAIL,
      payload: error,
    });
  }
  refreshState();
}

function* getCities() {
  try {
    const result = yield call(getCitiesService);
    yield put({
      type: BUILDING.CITY_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: BUILDING.CITY_GETS.FAIL,
      payload: error,
    });
  }
}

function* getDistricts() {
  try {
    const result = yield call(getDistrictsService);
    yield put({
      type: BUILDING.DISTRICT_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: BUILDING.DISTRICT_GETS.FAIL,
      payload: error,
    });
  }
}

function* getBuildingTypes() {
  try {
    const result = yield call(getBuildingTypesService);
    yield put({
      type: BUILDING.TYPE_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: BUILDING.TYPE_GETS.FAIL,
      payload: error,
    });
  }
}

function* addBuilding(action) {
  const { building, refreshState } = action.payload;
  try {
    const result = yield call(addBuildingService, { building });
    yield put({
      type: BUILDING.ADD.SUCCESS,
      payload: result,
    });
    refreshState({
      code: result.code,
      codeLanguage: result.codeLanguage,
      data: result.data,
    });
  } catch (error) {
    yield put({
      type: BUILDING.ADD.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* updateBuilding(action) {
  const { building, refreshState } = action.payload;
  try {
    const result = yield call(updateBuildingService, { building });
    yield put({
      type: BUILDING.UPDATE.SUCCESS,
      payload: result,
    });
    refreshState({
      code: result.code,
      codeLanguage: result.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: BUILDING.UPDATE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* createLockData(action) {
  const { idBuilding, lockDataAt, refreshState } = action.payload;

  try {
    const result = yield call(createLockDataService, { idBuilding, lockDataAt });
    yield put({
      type: LOCK_DATA_FORM_CREATE.SUCCESS,
      payload: result,
    });
    refreshState({
      code: result.code,
      codeLanguage: result.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: LOCK_DATA_FORM_CREATE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* updateLockData(action) {
  const { idBuilding, lockDataAt, prevLockDataAt, refreshState } = action.payload;
  try {
    const result = yield call(updateLockDataService, { idBuilding, lockDataAt, prevLockDataAt });
    yield put({
      type: LOCK_DATA_FORM_UPDATE.SUCCESS,
      payload: result,
    });
    refreshState({
      code: result.code,
      codeLanguage: result.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: LOCK_DATA_FORM_UPDATE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* getHistoryLockData(action) {
  const { idBuilding, page, limit, filterObject, refreshState } = action.payload;
  try {
    const result = yield call(getHistoryLockDataService, { idBuilding, page, limit, filterObject });
    yield put({
      type: LOCK_DATA_FORM_HISTORY.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: LOCK_DATA_FORM_HISTORY.FAIL,
      payload: error,
    });
  }
  refreshState();
}

function* getEmployees(action) {
  const { idBuilding, page, limit, filterObject, refreshState } = action.payload;
  try {
    const result = yield call(getEmployeesByPageService, { idBuilding, page, limit, filterObject });
    yield put({
      type: LOCK_DATA_FORM_EMPLOYEES_FILTER.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: LOCK_DATA_FORM_EMPLOYEES_FILTER.FAIL,
      payload: error,
    });
  }
  refreshState();
}

export default function* watchBuilding() {
  yield all([
    takeLatest(BUILDING.GET_BY_ID.REQUEST, getBuildingById),
    takeLatest(BUILDING.CITY_GETS.REQUEST, getCities),
    takeLatest(BUILDING.DISTRICT_GETS.REQUEST, getDistricts),
    takeLatest(BUILDING.TYPE_GETS.REQUEST, getBuildingTypes),
    takeLatest(BUILDING.ADD.REQUEST, addBuilding),
    takeLatest(BUILDING.UPDATE.REQUEST, updateBuilding),
    takeLatest(LOCK_DATA_FORM_CREATE.REQUEST, createLockData),
    takeLatest(LOCK_DATA_FORM_UPDATE.REQUEST, updateLockData),
    takeLatest(LOCK_DATA_FORM_HISTORY.REQUEST, getHistoryLockData),
    takeLatest(LOCK_DATA_FORM_EMPLOYEES_FILTER.REQUEST, getEmployees),
  ]);
}
