import {
  BUILDING,
  LOCK_DATA_FORM_CREATE,
  LOCK_DATA_FORM_HISTORY,
  LOCK_DATA_FORM_EMPLOYEES_FILTER,
  LOCK_DATA_FORM_UPDATE,
} from './building.types';

export const getBuildingById = ({ idBuilding, refreshState }) => ({
  type: BUILDING.GET_BY_ID.REQUEST,
  payload: {
    idBuilding,
    refreshState,
  },
});

export const getCities = () => ({
  type: BUILDING.CITY_GETS.REQUEST,
});

export const getDistricts = () => ({
  type: BUILDING.DISTRICT_GETS.REQUEST,
});

export const getBuildingTypes = () => ({
  type: BUILDING.TYPE_GETS.REQUEST,
});

export const addBuilding = ({ building, refreshState }) => ({
  type: BUILDING.ADD.REQUEST,
  payload: {
    building,
    refreshState,
  },
});

export const updateBuilding = ({ building, refreshState }) => ({
  type: BUILDING.UPDATE.REQUEST,
  payload: {
    building,
    refreshState,
  },
});

export const createLockData = ({ idBuilding, lockDataAt, refreshState }) => ({
  type: LOCK_DATA_FORM_CREATE.REQUEST,
  payload: {
    idBuilding,
    lockDataAt,
    refreshState,
  },
});

export const refreshCreateLockData = () => ({
  type: LOCK_DATA_FORM_CREATE.REFRESH,
});

export const updateLockData = ({ idBuilding, lockDataAt, prevLockDataAt, refreshState }) => ({
  type: LOCK_DATA_FORM_UPDATE.REQUEST,
  payload: {
    idBuilding,
    lockDataAt,
    prevLockDataAt,
    refreshState,
  },
});

export const refreshUpdateLockData = () => ({
  type: LOCK_DATA_FORM_UPDATE.REFRESH,
});

export const getHistoryLockData = ({ idBuilding, page, filterObject, refreshState }) => ({
  type: LOCK_DATA_FORM_HISTORY.REQUEST,
  payload: {
    idBuilding,
    page,
    filterObject,
    refreshState,
  },
});

export const getEmployees = ({ idBuilding, page, limit, filterObject, refreshState }) => ({
  type: LOCK_DATA_FORM_EMPLOYEES_FILTER.REQUEST,
  payload: {
    idBuilding,
    page,
    limit,
    filterObject,
    refreshState,
  },
});
