import { language } from '../utils/language';

const RESPONSE_MAPPING = {
  SUCCESS: language.success,
  FAIL: language.system_error,
  SERVER_ERROR: language.error.server_error,

  DATA_INVALID: language.error.data_invalid,

  // unit group
  UNIT_GROUP_CANNOT_DELETE: language.error.unit_group_cannot_delete,
  UNIT_GROUP_TEMPLATE_PAYLOAD_MALFORMED: language.error.the_content_contains_invalid_keywords,
  UNIT_GROUP_LOCK_DATE_ACTIVE_MISSING: language.error.unit_group_lock_date_active_missing,
  UNIT_GROUP_LOCK_DATE_INVALID: language.error.unit_group_lock_date_invalid,
  UNIT_GROUP_LOCK_DATE_EXISTED: language.error.unit_group_lock_date_existed,
  UNIT_GROUP_LOCK_DATE_DUPLICATE: language.error.unit_group_lock_date_duplicate,
  UNIT_GROUP_PROJECT_CODE_INVALID: language.error.the_project_code_is_not_valid,

  // unit
  UNIT_DELETE_ERROR: language.error.apartment_can_not_delete,
  UNIT_AREA_VALIDATE: language.error.apartment_area_validate,
  UNIT_NAME_UNIQUE: language.error.unit_name_unique,
  DELETE_APARTMENT_ERROR: language.error.apartment_can_not_delete,
  UNIT_INVALID_DATA: language.error.data_invalid,

  // user
  USER_EMAIL_UNIQUE: language.error.user_email_unique,
  USER_EMAIL_NOT_VERIFY: language.error.user_email_not_verify,
  USER_NOT_ACTIVE: language.error.user_not_active,
  USER_LOGIN_FAILED: language.error.login_unsuccessful,
  USER_PHONE_UNIQUE: language.error.resident_phone_unique, // temp error
  USER_EMAIL_NOT_FOUND: language.user_email_not_found,

  // floor
  FLOOR_DELETE_ERROR: language.error.floor_can_not_delete,

  // department
  DEPARTMENT_NAME_UNIQUE: language.error.department_name_unique,
  DEPARTMENT_DELETE_ERROR: language.error.department_delete_error,

  // employee
  EMPLOYEE_POSITION_MISSING: language.error.employee_position_missing,
  EMPLOYEE_DEPARTMENT_MISSING: language.error.employee_department_missing,
  EMPLOYEE_EMAIL_UNIQUE: language.error.employee_email_unique,
  EMPLOYEE_PHONE_UNIQUE: language.error.employee_phone_unique,
  EMPLOYEE_NOT_HAVE_PERMISSION: language.error.employee_not_have_permission,
  EMPLOYEE_ALREADY_EXISTS: language.error.employee_already_exists,

  // resident
  RESIDENT_UNIT_MISSING: language.error.resident_unit_missing,
  RESIDENT_IDENTIFIER_UNIQUE: language.error.resident_identifier_unique,
  RESIDENT_EMAIL_UNIQUE: language.error.resident_email_unique,
  RESIDENT_PHONE_UNIQUE: language.error.resident_phone_unique,
  RESIDENT_VISA_UNIQUE: language.error.resident_visa_unique,
  RESIDENT_ROLE_UNIQUE: language.error.resident_role_unique,
  RESIDENT_ACTIVE_ERROR: language.error.resident_active_error,

  // contract
  CONTRACT_TARGET_MISSING: language.error.contract_target_missing,
  CONTRACT_CONTRACT_TEMPLATE_MISSING: language.error.contract_contract_template_missing,
  CONTRACT_REGISTRATION_PLATE_UNIQUE: language.error.contract_registration_plate_unique,
  CONTRACT_CARD_ID_EXISTED: language.contract_card_id_existed,

  // label
  LABEL_TITLE_UNIQUE: language.error.label_title_unique,
  GROUPLABEL_ABBREVIATE_TITLE_UNIQUE: language.status.invalid_abbreviate_title_label,
  LABEL_ABBREVIATE_TITLE_UNIQUE: language.status.invalid_abbreviate_title_label,
  LABEL_DELETE_ERROR: language.can_not_delete_using_label,
  LABEL_GROUP_DELETE_ERROR: language.can_not_delete_group_contain_child_or_label,

  // fund
  FUND_NAME_UNIQUE: language.error.fund_name_unique,
  FUND_DELETE_ERROR: language.error.delete_fund_failed,
  FUND_IS_NOT_EXIST_IN_UNIT_GROUP: language.error.fund_is_not_exist_in_unit_group,

  // upload
  UPLOAD_EXCEL_DATA_EMPTY: language.error.upload_excel_data_empty,
  UPLOAD_FILE_NOT_FOUND: language.error.upload_file_not_found,
  UPLOAD_FILE_TYPE_ERROR: language.error.upload_file_type_error,

  // position
  POSITION_NAME_UNIQUE: language.error.position_name_unique,
  POSITION_DELETE_ERROR: language.error.position_delete_error,

  // service
  SERVICE_NAME_UNIQUE: language.error.service_name_unique,
  SERVICE_IDENTIFIER_UNIQUE: language.error.service_identifier_unique,

  // contract template
  CONTRACTTEMPLATE_NAME_UNIQUE: language.error.contract_template_name_unique,

  // receipt
  RECEIPT_CANCEL_ERROR: language.error.money_in_apartment_account_not_enough_to_cancel_receipt,
  RECEIPT_HAS_BILL_IS_ADJUSTED_DOWN: language.error.receipt_has_bill_is_adjusted_down,
  RECEIPT_LOCKED: language.receipt_locked,
  DEBT_BILL_IN_OTHER_RECEIPT: language.receipts_contain_partial_bills_linked_to_other_receipts,

  // pay slip
  PAYSLIP_CREATE_ERROR: language.error.total_payment_greater_than_unit_account_balance,
  TOTAL_PAYMENT_EXCEEDING: language.error.maximum_amount_can_be_spent_unit_account_balance,

  // announcement
  ANNOUNCEMENT_CONTENT_VALIDATE: language.error.announcement_content_not_null,
  ANNOUNCEMENT_SENDTYPES_VALIDATE: language.error.announcement_send_type_not_null,

  // payment
  BILL_HAVE_BEEN_PAID: language.error.bill_have_been_paid,
  BILL_HAVE_BEEN_REMOVED: language.error.bill_have_been_removed,
  BILL_INCURRED_CREATED_FOR_INVALID: language.error.bill_incurred_created_for_invalid,

  // request
  TASK_DUEDATE_VALIDATE: language.error.request_task_due_date_validate,
  REQUEST_COMPLETED_FAIL: language.error.request_change_progress_complete_failure,
  REQUEST_CANCELLED_FAIL: language.error.request_change_progress_cancel_failure,
  REQUEST_DUEDATE_VALIDATE: language.error.request_task_due_date_validate,

  INTERNET_NOT_CONNECTED: language.error.internet_not_connected,
  USER_EMAIL_VALIDATE: language.error.user_email_validate,

  // Adjust bill down
  AMOUNT_OF_MONEY_NEED_ADJUST_NOT_LESS_THAN_ZERO: language.error.amount_of_money_need_adjust_not_less_than_zero,
  ORIGIN_BILL_TOTAL_PAYMENT_LTE_AMOUNT_MONEY_NEED_ADJUST: language.error.origin_bill_total_payment_lte_amount_money_need_adjust,
  AMOUNT_OF_MONEY_OF_UNIT_ACCOUNTS_LTE_THE_DIFFERENCE_BETWEEN_THE_TWO_ADJUSTMENTS:
    language.error.amount_of_money_of_unit_accounts_lte_the_difference_between_the_two_adjustments,
  UNITACCOUNT_BALANCE_VALIDATE: language.label.unit_account_balance_validate,

  BILL_PARTIAL_PAID: language.error.bill_partial_paid,
  BILL_PAID: language.error.bill_paid_not_cancel,
  BILL_CANCELLED: language.error.bill_not_cancel,
  EXISTS_ADJUST_BILL_HAVE_NOT_CANCELLED_YET: language.error.exists_adjust_bill_have_not_cancelled_yet,
  AMOUNT_OF_MONEY_OF_UNIT_ACCOUNTS_LTE_AMOUNT_OF_MONEY_OF_BILL: language.error.amount_of_money_of_unit_accounts_lte_amount_of_money_of_bill,
  // Deparment
  DEPARTMENT_ADMIN_ERROR: language.error.cannot_update_or_delete_on_admin_department,

  // Position
  POSITION_ADMIN_ERROR: language.error.cannot_update_or_delete_on_admin_position,
  POSITION_ADMIN_CANNOT_EMPTY: language.error.admin_position_must_exist_at_least_1_employee,

  // amenities
  UTILITY_LIMIT_ORDER_PER_TIME_TYPE_INVALID: language.utility_limit_order_per_time_type_invalid,
  UTILITY_LIMIT_ORDER_PER_TIME_INVALID: language.utility_limit_order_per_time_invalid,
  UTILITY_NAME_REQUIRED: language.utility_name_required,
  UTILITY_NAME_INVALID_LENGTH: language.utility_name_invalid_length,
  UTILITY_PAYMENT_METHOD_INVALID: language.utility_payment_method_invalid,
  UTILITY_PAY_BEFORE_TIME_INVALID: language.utility_pay_before_time_invalid,
  UTILITY_PAY_BEFORE_TIME_TYPE_INVALID: language.utility_pay_before_time_type_invalid,
  FORMULA_TYPE_INVALID: language.formula_type_invalid,
  UTILITY_CODE_REQUIRED: language.utility_code_required,
  UTILITY_CODE_UNIQUE: language.utility_code_unique,
  UTILITY_PHOTO_REQUIRED: language.utility_photo_required,
  UTILITY_PRIORITY_IN_APP_REQUIRED: language.utility_priority_in_app_required,
  UTILITY_PRIORITY_IN_APP_INVALID: language.utility_priority_in_app_invalid,
  UTILITY_DESCRIPTION_INVALID: language.utility_description_invalid,
  UTILITY_LOCATION_INVALID: language.utility_location_invalid,
  UTILITY_PRE_ORDER_TIME_INVALID: language.utility_pre_order_time_invalid,
  UTILITY_LIMIT_ORDER_PER_USAGE_INVALID: language.utility_limit_order_per_usage_invalid,
  UTILITY_LIMIT_ORDER_PER_USAGE_TYPE_INVALID: language.utility_limit_order_per_usage_type_invalid,
  UTILITY_DEPOSIT_INVALID: language.utility_deposit_invalid,
  UTILITY_TYPE_INVALID: language.utility_type_invalid,
  UTILITY_FORMULAS_INVALID: language.utility_formulas_invalid,
  UTILITY_FORMULAS_FORMAT_INVALID: language.utility_formulas_format_invalid,
  UTILITY_IS_HAVING_ORDERS_TO_PROCESS_ERROR: language.utility_is_having_orders_to_process,
  // report stored
  SAVEDREPORT_NAME_UNIQUE: language.error.report_exist,

  // Adjust bill
  ORIGIN_BILL_NOT_FOUND: language.error.no_original_bill_found,
  ORIGIN_BILL_CAN_NOT_ADJUST: language.error.unable_to_update_an_adjustable_voucher_for_this_invoice,
  ORIGIN_BILL_CANCELLED: language.error.the_original_bill_has_been_canceled,
  ADJUST_BILL_NOT_EXISTS: language.error.bill_does_not_exist,
  ADJUST_BILL_CAN_NOT_UPDATE: language.error.unable_to_update_an_adjustable_voucher_for_this_invoice,
  ADJUST_BILL_PAID: language.error.bill_paid,
  ADJUST_BILL_CANCELLED: language.error.bill_has_been_canceled,
  ORIGIN_BILL_ADJUSTED: language.error.only_make_adjustments_1_time_for_1_bill,
  ADJUST_BILL_CAN_NOT_ADJUST: language.error.unable_to_adjust_for_this_voucher,
  BILL_CREATED_BEFORE_LOCK_DATE: language.error.can_not_cancel_bills_created_before_lock_date,

  // Payment
  RECEIPT_CREATE_MULTI_ERROR: language.error.payment_of_many_apartments_failed,

  TOTAL_PAYMENT_BILL_ADJUST_DOWN_OVER_UNIT_ACCOUNT_BALANCE: language.error.the_payment_for_the_adjustment_bill_exceeds_the_flat_account,
  FIELD_IS_REQUIRED: language.error.the_input_contains_invalid_keywords,

  // Amenities Booking
  CAN_NOT_PAY_BECAUSE_THIS_AMENITY_BOOKING_IS_NOT_PENDING:
    language.error.cannot_pay_for_this_amenity_because_it_is_not_pending_or_waiting_for_complete,
  CAN_NOT_REFUND_BECAUSE_THIS_AMENITY_BOOKING_IS_NOT_WAITING_FOR_CANCELLED_OR_COMPLETED: language.error.amenities_booking_payment_error_1,
  AMENTITY_BOOKING_HAS_ALREADY_BEEN_REFUNDED: language.error.amenities_booking_payment_error_2,
  CANNOT_REFUND_ZERO_MONEY: language.error.amenities_booking_payment_error_3,
  AMENITY_BOOKING_PAY_AFTER_END_DATE_USAGE: language.error.utility_bills_cannot_be_paid_with_a_usage_date,

  // Template
  TEMPLATE_NOT_FOUND: language.template_has_not_been_set_up,

  // card
  CARD_CODE_UNIQUE: language.card_code_existed,

  // asset
  ASSET_INVALID_PLACE_TYPE: language.asset_invalid_place_type,
  ASSET_STATUS_VALIDATE: language.asset_invalid_status,
  ASSET_INVALID_LOCATION: language.asset_invalid_location,
  ASSET_INVALID_DEPARTMENT: language.asset_invalid_manager_unit,
  ASSET_INVALID_SUPPLIER: language.asset_invalid_provider,
  ASSET_INVALID_MAINTENANCE_UNIT: language.asset_invalid_maintenance_unit,

  // partner
  PARTNER_COMPANY_NAME_UNIQUE: language.partner_company_name_unique,

  // supply
  SUPPLY_INVALID_PARTNER: language.supply_invalid_partner,
  SUPPLY_INVALID_DEPARTMENT: language.supply_invalid_department,
  SUPPLY_INVALID_EMPLOYEE: language.supply_invalid_employee,
  SUPPLY_INVALID_STATUS: language.supply_invalid_status,

  // access-management
  VISITOR_INACTIVE: language.visitor_inactive,
  // inventory-transfer-management
  ELEVATOR_RESERVATION_INACTIVE: language.elevator_reservation_inactive,
  // construction-management
  CONSTRUCTION_REGISTRATION_INACTIVE: language.construction_registration_inactive,
  ASSET_INVALID_LAST_MAINTENANCE: language.asset_invalid_last_maintenance,
  ASSET_INVALID_CYCLE_MAINTENANCE: language.asset_invalid_cycle_maintenance,
  ASSET_INVALID_NEXT_MAINTENANCE_DATE: language.asset_invalid_next_maintenance_date,
  ASSET_INVALID_EMPLOYEE: language.asset_invalid_employee,
  ASSET_CODE_EXISTED: language.asset_code_existed,
};

export default RESPONSE_MAPPING;
