import { useContext } from 'react';
import { getPermissionService } from 'services/user';
// eslint-disable-next-line import/no-cycle
import { Context as GlobalContext } from 'globalContext';
import { NOT_NEED_PERMISSION } from 'shared/constants';

function hasAnyAuthority(authorities = [], hasAnyAuthorities = []) {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some((auth) => [...authorities, NOT_NEED_PERMISSION].includes(auth));
  }
  return false;
}

const usePermission = () => {
  const { authorities, setAuthorities } = useContext(GlobalContext);

  async function getPermission(idBuilding) {
    const permissions = await getPermissionService({
      idBuilding,
    });
    const newAuthorities = permissions?.data?.map((permission) => permission.name) ?? [];
    setAuthorities(newAuthorities);
    return newAuthorities;
  }

  function checkViewPermission(hasAnyAuthorities) {
    return hasAnyAuthority(authorities, hasAnyAuthorities);
  }

  return { checkViewPermission, getPermission, authorities };
};

export default usePermission;
