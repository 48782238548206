/** @jsx jsx */
import 'moment/locale/eu';
import 'moment/locale/vi';
import { hot } from 'react-hot-loader';
import { Switch, Route } from 'react-router-dom';
import { jsx } from '@emotion/react';
import React, { Suspense, useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { checkMaintenance } from 'services/base';
import PermissionDenied from 'components/PermissionDenied';
import { language } from 'shared/utils';
import { useFcm } from 'shared/helpers';

const { AUTH_METHOD } = process.env;

const Print = React.lazy(() => import('components/Print'));

const SSOLogin = React.lazy(() => import('containers/SSOAuth/Login'));

const NormalLogin = React.lazy(() => import('containers/Auth/Login'));

const AuthRedirect = React.lazy(() => import('containers/SSOAuth/Redirect'));

const Register = React.lazy(() => import('containers/Auth/Register'));

const ForgotPassword = React.lazy(() => import('containers/Auth/ForgotPassword'));

const NewPassword = React.lazy(() => import('containers/Auth/NewPassword'));

const ApplicationComponent = React.lazy(() => import('containers/Application'));

function Routes() {
  const [loading, setLoading] = useState(true);
  const [maintenance, setMaintenance] = useState(false);
  const { requestPermission, handleCallback } = useFcm();

  useEffect(() => {
    (async () => {
      setLoading(true);

      const result = await checkMaintenance();

      if (result?.data?.status) setMaintenance(true);

      setLoading(false);
    })();
    (async () => {
      await requestPermission();
      await handleCallback();
    })();
  }, []);

  if (loading) return <Loading isLoading />;

  if (maintenance) {
    return <PermissionDenied src="/assets/images/maintenance.png" description={language.system_maintenance} />;
  }

  return (
    <Suspense fallback={<Loading isLoading />}>
      <Switch>
        <Route path="/print" component={Print} />
        <Route path="/login" component={AUTH_METHOD === 'SSO' ? SSOLogin : NormalLogin} />
        <Route path="/register" component={Register} />
        <Route path="/password/forgot" component={ForgotPassword} />
        <Route path="/password/set-new-password" component={NewPassword} />
        <Route path="/auth-redirect" component={AuthRedirect} />
        <Route path="/buildings/:idBuilding/*" component={ApplicationComponent} />
        <Route path="/" component={ApplicationComponent} />
      </Switch>
    </Suspense>
  );
}

export default hot(module)(Routes);
