import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const UNIT_GROUP_TYPE_GETS = `${SERVER}/unit-group-type/list`;
const UNIT_GROUP = `${SERVER}/unit-group`;
const UNIT_GROUP_GET_BY_ID = (id) => `${UNIT_GROUP}/${id}`;
const UNIT_GROUP_ADD = UNIT_GROUP;
const UNIT_GROUP_UPDATE = (id) => `${UNIT_GROUP}/${id}`;
const UNIT_GROUP_GET_LIST = `${UNIT_GROUP}/list`;
const LOCK_DATA_CREATE = `${UNIT_GROUP}/lock-date`;
const LOCK_DATA_UPDATE = `${UNIT_GROUP}/lock-date/update`;
const LOCK_DATA_HISTORY = `${UNIT_GROUP}/lock-date/list`;

export const getBuildingsService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_GROUP_GET_LIST,
      method: METHOD.GET,
      options: {
        params: {
          // type: BUILDING,
        },
      },
    },
  });
  return response;
};

export const getBuildingByIdService = async ({ idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_GROUP_GET_BY_ID(idBuilding),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const addBuildingService = async ({ building }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_GROUP_ADD,
      method: METHOD.POST,
      body: building,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: building.id,
        },
      },
    },
  });
  return response;
};

export const updateBuildingService = async ({ building }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_GROUP_UPDATE(building.id),
      method: METHOD.PUT,
      body: building,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: building.id,
        },
      },
    },
  });
  return response;
};

export const getBuildingTypesService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_GROUP_TYPE_GETS,
      method: METHOD.GET,
    },
  });
  return response;
};

export const createLockDataService = async ({ lockDataAt, idBuilding }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    lockDataAt,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LOCK_DATA_CREATE,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const updateLockDataService = async ({ idBuilding, lockDataAt, prevLockDataAt }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    lockDataAt,
    prevLockDataAt,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LOCK_DATA_UPDATE,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const getHistoryLockDataService = async ({ idBuilding, filterObject, limit, page }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filterObject,
    limit,
    page,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LOCK_DATA_HISTORY,
      method: METHOD.GET,
      options: {
        headers,
        params,
      },
    },
  });
  return response;
};
