const resizeImage = (url, size) => {
  if (!url) {
    return null;
  }
  if (url && size) {
    const arr = url.split('/');
    return `${arr[0]}//${arr[2]}/${size}/${arr[3]}`;
  }
  return url;
};

export default resizeImage;
