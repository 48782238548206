import { combineReducers } from 'redux';
import applicationReducer, { initState as initStateApplication } from 'containers/Application/ducks';
import apartmentDetailReducer, { initState as initStateApartmentDetail } from 'containers/Apartment/Detail/ducks';
import residentFormReducer, { initState as initStateResidentForm } from 'containers/Resident/ResidentForm/ducks';
import residentReducer, { initState as initStateResident } from 'containers/Resident/ducks';
import configSubdivisionReducer, { initState as initStateConfigSubdivision } from 'containers/Config/ConfigBuilding/Subdivision/ducks';
import configBuildingReducer, { initState as initStateConfigBuilding } from 'containers/Config/ConfigBuilding/Building/ducks';
import configBlockReducer, { initState as initStateConfigBlock } from 'containers/Config/ConfigBuilding/Block/ducks';
import configFloorReducer, { initState as initStateConfigFloor } from 'containers/Config/ConfigBuilding/FloorV2/ducks';
import configApartmentReducer, { initState as initStateConfigApartment } from 'containers/Config/ConfigBuilding/ApartmentV2/ducks';
import configServiceReducer, { initState as initStateConfigService } from 'containers/Config/ConfigService/ducks';
import configAmenitiesReducer, { initState as initStateConfigAmenities } from 'containers/Config/ConfigAmenities/ducks';
import amenitiesOrderReducer, { initState as initStateAmenitiesOrder } from 'containers/AmenitiesOrder/ducks';
import amenitiesOrderDetailReducer, { initState as initStateAmenitiesOrderDetail } from 'containers/AmenitiesOrder/AmenitiesDetail/ducks';
import amenitiesOrderConfirmChangeStatusReducer, {
  initState as initStateAmenitiesOrderConfirmChangeStatus,
} from 'containers/AmenitiesOrder/AmenitiesOrderConfirmChangeStatus/ducks';
import paymentReducer, { initState as initStatePayment } from 'containers/Payment/ducks';

export const initState = {
  application: initStateApplication,
  apartmentDetail: initStateApartmentDetail,
  residentForm: initStateResidentForm,
  resident: initStateResident,
  configBuilding: initStateConfigBuilding,
  configSubdivision: initStateConfigSubdivision,
  configBlock: initStateConfigBlock,
  configFloor: initStateConfigFloor,
  configApartment: initStateConfigApartment,
  configService: initStateConfigService,
  configAmenities: initStateConfigAmenities,
  amenitiesOrder: initStateAmenitiesOrder,
  amenitiesOrderDetail: initStateAmenitiesOrderDetail,
  amenitiesOrderConfirmChangeStatus: initStateAmenitiesOrderConfirmChangeStatus,
  payment: initStatePayment,
};

const reducers = combineReducers({
  application: applicationReducer,
  apartmentDetail: apartmentDetailReducer,
  residentForm: residentFormReducer,
  resident: residentReducer,
  configBuilding: configBuildingReducer,
  configSubdivision: configSubdivisionReducer,
  configBlock: configBlockReducer,
  configFloor: configFloorReducer,
  configApartment: configApartmentReducer,
  configService: configServiceReducer,
  configAmenities: configAmenitiesReducer,
  amenitiesOrder: amenitiesOrderReducer,
  amenitiesOrderDetail: amenitiesOrderDetailReducer,
  amenitiesOrderConfirmChangeStatus: amenitiesOrderConfirmChangeStatusReducer,
  payment: paymentReducer,
});

export default reducers;
