import { language } from '../utils/language';

export const CONFIG_BUILDING_MAIN_MENUS_BUILDING = 'CONFIG_BUILDING_MAIN_MENUS_BUILDING';
export const CONFIG_BUILDING_MAIN_MENUS_SUBDIVISION = 'CONFIG_BUILDING_MAIN_MENUS_SUBDIVISION';
export const CONFIG_BUILDING_MAIN_MENUS_BLOCK = 'CONFIG_BUILDING_MAIN_MENUS_BLOCK';
export const CONFIG_BUILDING_MAIN_MENUS_FLOOR = 'CONFIG_BUILDING_MAIN_MENUS_FLOOR';
export const CONFIG_BUILDING_MAIN_MENUS_APARTMENT = 'CONFIG_BUILDING_MAIN_MENUS_APARTMENT';

export const DEFAULT_CONFIG_BUILDING_SUBDIVISION_FILTER = {
  name: undefined,
};

export const DEFAULT_CONFIG_BUILDING_BLOCK_FILTER = {
  name: '',
  subdivisionId: '',
};

export const DEFAULT_CONFIG_BUILDING_FLOOR_FILTER = {
  name: '',
  subdivisionId: '',
  blockId: '',
};

export const DEFAULT_CONFIG_BUILDING_APARTMENT_FILTER = {
  name: '',
  subdivisionId: '',
  blockId: '',
  floorId: '',
  apartmentTypeId: '',
  buildStatus: '',
  dateOfTakingApartment: [],
};

export const CONFIG_BUILDING_MAIN_MENUS = [
  {
    label: language.van_phuc_urban,
    value: CONFIG_BUILDING_MAIN_MENUS_BUILDING,
  },
  // {
  //   label: language.subdivision,
  //   value: CONFIG_BUILDING_MAIN_MENUS_SUBDIVISION,
  // },
  // {
  //   label: language.block,
  //   value: CONFIG_BUILDING_MAIN_MENUS_BLOCK,
  // },
  {
    label: language.floor,
    value: CONFIG_BUILDING_MAIN_MENUS_FLOOR,
  },
  {
    label: language.apartment,
    value: CONFIG_BUILDING_MAIN_MENUS_APARTMENT,
  },
];
