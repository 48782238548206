import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const EMPLOYEE = `${SERVER}/employee`;
const EMPLOYEE_GET_LIST = `${EMPLOYEE}/list`;

export const getEmployeesByPageService = async ({ idBuilding, page, limit, filterObject }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filterObject,
    limit,
    page,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EMPLOYEE_GET_LIST,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
};
