import moment from 'moment';
import {
  API_TYPE_CALL_API,
  API_TYPE_DOWNLOAD_FILE,
  BILL_CREATE_TYPE_MORE_ONE_MONTH,
  METHOD,
  SERVER,
  X_UNIT_GROUP_ID,
} from 'shared/constants';
import api from './api';

const BILL = `${SERVER}/bill`;
const BILL_SUM_TOTAL_PAYMENT = `${BILL}/sum-total`; // New API get list bill
const BILL_GET_LIST = `${BILL}/list`;
const BILL_EXPORT = `${BILL_GET_LIST}/export-data`;
const BILL_CREATE_AUTO = `${BILL}/auto`;
const BILL_CREATE_MANUAL = `${BILL}/manual`;
const BILL_DELETE_MULTI = `${BILL}/delete-multi`;
const BILL_UNPAID_GET = `${BILL}/unpaid`;
const BILL_ADJUST = `${BILL}/adjust`;
const BILL_SEND_GETS = `${BILL}/send-bill`;
const BILL_SEND_SUM_TOTAL_PAYMENT = `${BILL}/send-bill/sum-total`;
const BILL_LIST_NOT_GROUP_UNIT = `${BILL}/list-not-group-unit`;
const BILL_LIST_BY_UNIT = `${BILL}/list-bill-adjust-down-by-unit`;
export const BILL_SUM_PAYMENT = `${BILL}/sum-payment`;

export const createBillAutoService = async ({ targets, isAll, incurredCreatedFor, idBuilding }) => {
  const body = {
    targets,
    isAll,
    incurredCreatedFor,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_CREATE_AUTO,
      body,
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const createBillManualService = async ({
  startDate,
  endDate,
  targets,
  type,
  idBuilding,
  isAll,
  incurredCreatedFor,
  feePeriodType,
}) => {
  const body = {
    startDate,
    endDate,
    targets,
    type: type === BILL_CREATE_TYPE_MORE_ONE_MONTH ? 'FULL_MONTH' : type,
    isAll,
    incurredCreatedFor,
    feePeriodType,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_CREATE_MANUAL,
      body,
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getBillUnpaidByUnitIdService = async ({ idBuilding, page, limit, idUnit }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_UNPAID_GET,
      method: METHOD.GET,
      options: {
        params: {
          unitId: idUnit,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

// New API sum total payment
export const sumTotalPaymentService = async ({ filterObject, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_SUM_TOTAL_PAYMENT,
      method: METHOD.GET,
      options: {
        params: {
          ...filterObject,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const deleteBillService = async ({ isAll, idBills, idBuilding, filterObject }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_DELETE_MULTI,
      body: {
        isAll,
        array: idBills,
        filterObject: {
          ...filterObject,
          from: filterObject?.time?.[0] ? moment(filterObject?.time?.[0]).toDate() : null,
          to: filterObject?.time?.[1] ? moment(filterObject?.time?.[1]).toDate() : null,
        },
      },
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export async function exportBillService({ filterObject, page, limit, idBuilding }) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: BILL_EXPORT,
      method: METHOD.GET,
      options: {
        params: {
          ...filterObject,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export const createBillAdjustService = async ({ idOriginalBill, reason, totalPayment, type, incurredCreatedFor, idBuilding }) => {
  const body = {
    originBillId: idOriginalBill,
    reason,
    totalPayment,
    type,
    incurredCreatedFor,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_ADJUST,
      body,
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const updateBillAdjustService = async ({ idAdjustBill, reason, totalPayment, incurredCreatedFor, idBuilding }) => {
  const body = {
    billId: idAdjustBill,
    reason,
    totalPayment,
    incurredCreatedFor,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_ADJUST,
      body,
      method: METHOD.PUT,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

// Api for Send Bill
export const getListSendBillsService = async ({ filterObject, page, limit, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_SEND_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filterObject,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const sumTotalPaymentSendBillService = async ({ filterObject, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_SEND_SUM_TOTAL_PAYMENT,
      method: METHOD.GET,
      options: {
        params: {
          ...filterObject,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getListBillsNotGroupUnitService = async ({ filterObject, page, limit, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_LIST_NOT_GROUP_UNIT,
      method: METHOD.GET,
      options: {
        params: {
          ...filterObject,
          from: filterObject?.time?.[0] ? moment(filterObject?.time?.[0]).toDate() : null,
          to: filterObject?.time?.[1] ? moment(filterObject?.time?.[1]).toDate() : null,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getListBillByUnitService = async ({ filterObject, page, limit, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_LIST_BY_UNIT,
      method: METHOD.GET,
      options: {
        params: {
          ...filterObject,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getBillsService = async ({ params, headers }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_GET_LIST,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
};

export const sumPaymentService = async ({ params, headers }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BILL_SUM_PAYMENT,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
};
