import { createAction } from 'shared/utils';

export const BUILDING = {
  GET_BY_ID: createAction('BUILDING_GET_BY_ID'),
  ADD: createAction('BUILDING_ADD'),
  UPDATE: createAction('BUILDING_UPDATE'),
  CITY_GETS: createAction('BUILDING_CITY_GETS'),
  DISTRICT_GETS: createAction('BUILDING_DISTRICT_GETS'),
  TYPE_GETS: createAction('BUILDING_TYPE_GETS'),
};

export const LOCK_DATA_FORM_CREATE = createAction('LOCK_DATA_FORM_CREATE');
export const LOCK_DATA_FORM_UPDATE = createAction('LOCK_DATA_FORM_UPDATE');
export const LOCK_DATA_FORM_HISTORY = createAction('LOCK_DATA_FORM_HISTORY');
export const LOCK_DATA_FORM_EMPLOYEES_FILTER = createAction('LOCK_DATA_FORM_EMPLOYEES_FILTER');
