import moment from 'moment';
import convertArrToObj from '../utils/convertArrToObj';
import { language } from '../utils/language';

export const SERVICE_FORMULA_TYPE_NUMBER_USE = 'NUMBER_USE';
export const SERVICE_FORMULA_TYPE_PERCENT = 'PERCENT';
export const SERVICE_FORMULA_TYPE_ACCUMULATED = 'ACCUMULATED';
export const SERVICE_SURCHARGE_TYPE_PERCENT = 'PERCENT';
export const SERVICE_SURCHARGE_TYPE_PRICE_MULTI_AMOUNT = 'PRICE_MULTI_AMOUNT';
export const SERVICE_PROMOTION_TYPE_PERCENT = 'PERCENT';

export const FORMULA_TYPES = [
  {
    value: SERVICE_FORMULA_TYPE_ACCUMULATED,
    label: language.accumulate,
  },
  {
    value: SERVICE_FORMULA_TYPE_NUMBER_USE,
    label: language.number_use,
  },
  {
    value: SERVICE_FORMULA_TYPE_PERCENT,
    label: language.percent,
  },
];

export const FORMULA_TYPE_OBJ = convertArrToObj(FORMULA_TYPES, 'value');

export const SERVICE_TYPE_SAVE_FROM_TO = 'SAVE_FROM_TO';
export const SERVICE_TYPE_SAVE_NUMBER_USE = 'SAVE_NUMBER_USE';
export const SERVICE_TYPE_REGISTER_ONE_TIME = 'REGISTER_ONE_TIME';
export const SERVICE_TYPE_REGISTER_LONG_TERM = 'REGISTER_LONG_TERM';
export const SERVICE_TYPE_PARKING = 'PARKING';

export const SERVICE_TYPES = [
  {
    id: SERVICE_TYPE_SAVE_FROM_TO,
    name: language.save_from_to,
  },
  {
    id: SERVICE_TYPE_SAVE_NUMBER_USE,
    name: language.save_number_use,
  },
  {
    id: SERVICE_TYPE_REGISTER_ONE_TIME,
    name: language.register_one_time,
  },
  {
    id: SERVICE_TYPE_REGISTER_LONG_TERM,
    name: language.register_long_term,
  },
];

export const SERVICE_READING_DEFAULT_FILTER = {
  feeNotificationPeriod: moment(),
  closingPeriod: moment().subtract(1, 'month'),
  opening: moment().subtract(1, 'month').startOf('month'),
  closing: moment().subtract(1, 'month').endOf('month'),
  apartment: '',
  contractTemplateId: '',
};

export const SERVICE_FIXED_DEFAULT_FILTER = {
  apartment: '',
  contractTemplateId: '',
};

export const SERVICE_PARKING_DEFAULT_FILTER = {
  apartment: '',
  registrationPlate: '',
  registerDate: [],
  dayOfUsing: [],
  contractTemplateId: '',
  status: 'ACTIVE',
};

export const SERVICE_REGISTER_DEFAULT_FILTER = {
  apartment: '',
  registerDate: [],
  dayOfUsing: [],
  contractTemplateId: '',
  status: 'ACTIVE',
};

export const PREVIOUS_MONTH = 'PREVIOUS_MONTH';
export const CURRENT_MONTH = 'CURRENT_MONTH';
export const NEXT_MONTH = 'NEXT_MONTH';
