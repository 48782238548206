import { language } from 'shared/utils';

export const SERVICE_STATUS_ACTIVE = 'ACTIVE';
export const SERVICE_STATUS_INACTIVE = 'INACTIVE';

export const SERVICE_TYPE_SAVE_FROM_TO = 'SAVE_FROM_TO';
export const SERVICE_TYPE_SAVE_NUMBER_USE = 'SAVE_NUMBER_USE';
export const SERVICE_TYPE_REGISTER_ONE_TIME = 'REGISTER_ONE_TIME';
export const SERVICE_TYPE_REGISTER_LONG_TERM = 'REGISTER_LONG_TERM';

export const SERVICE_TYPE_ACCUMULATED = 'ACCUMULATED';
export const SERVICE_TYPE_FIXED = 'FIXED';
export const SERVICE_TYPE_PARKING = 'PARKING';

export const SERVICE_FORMULA_TYPE_NUMBER_USE = 'NUMBER_USE';
export const SERVICE_FORMULA_TYPE_PERCENT = 'PERCENT';
export const SERVICE_FORMULA_TYPE_ACCUMULATED = 'ACCUMULATED';

export const SERVICE_VEHICLE_MOTORBIKE = 'MOTORBIKE';
export const SERVICE_VEHICLE_CAR = 'CAR';
export const SERVICE_VEHICLE_BICYCLE = 'BICYCLE';
export const SERVICE_VEHICLE_ELECTRIC_BICYCLE = 'ELECTRIC_BICYCLE';
export const SERVICE_VEHICLE_ELECTRIC_MOTORBIKE = 'ELECTRIC_MOTORBIKE';

export const SERVICE_FORMULA_FIXED_VEHICLE = 'SERVICE_FORMULA_FIXED_VEHICLE';
export const SERVICE_FORMULA_FIXED_DEFAULT = 'SERVICE_FORMULA_FIXED_DEFAULT';

export const PERCENT = 'PERCENT';
export const PRICE_MULTI_AMOUNT = 'PRICE_MULTI_AMOUNT';

export const TEXT = 'TEXT';
export const NUMBER = 'NUMBER';
export const DATE = 'DATE';

export const PREVIOUS_MONTH = 'PREVIOUS_MONTH';
export const CURRENT_MONTH = 'CURRENT_MONTH';
export const NEXT_MONTH = 'NEXT_MONTH';

export const SERVICE_VEHICLE_MAPPING = {
  [SERVICE_VEHICLE_MOTORBIKE]: language.label.motorbike,
  [SERVICE_VEHICLE_CAR]: language.label.car,
  [SERVICE_VEHICLE_BICYCLE]: language.label.bicycle,
  [SERVICE_VEHICLE_ELECTRIC_BICYCLE]: language.label.electric_bike,
  [SERVICE_VEHICLE_ELECTRIC_MOTORBIKE]: language.label.electric_motorbike,
};

export const SERVICE_STATUS_MAPPING = {
  [SERVICE_STATUS_ACTIVE]: language.label.active,
  [SERVICE_STATUS_INACTIVE]: language.label.inactive,
};

export const SERVICE_TYPE_OLD_MAPPING = {
  [SERVICE_TYPE_SAVE_FROM_TO]: language.label.save_from_to,
  [SERVICE_TYPE_SAVE_NUMBER_USE]: language.label.save_number_use,
  [SERVICE_TYPE_REGISTER_ONE_TIME]: language.label.register_one_time,
  [SERVICE_TYPE_REGISTER_LONG_TERM]: language.label.register_long_term,
};

export const SERVICE_TYPE_MAPPING = {
  [SERVICE_TYPE_ACCUMULATED]: language.label.number_latch,
  [SERVICE_TYPE_FIXED]: language.label.permanent,
  [SERVICE_TYPE_PARKING]: language.label.parking,
};

export const SERVICE_FORMULA_TYPE_MAPPING = {
  [SERVICE_FORMULA_TYPE_NUMBER_USE]: language.label.permanent,
  [SERVICE_FORMULA_TYPE_PERCENT]: language.label.percent,
  [SERVICE_FORMULA_TYPE_ACCUMULATED]: language.label.cumulative,
};

export const SERVICE_VEHICLE_ARRAY = [
  {
    id: SERVICE_VEHICLE_MOTORBIKE,
    name: language.label.motorbike,
  },
  {
    id: SERVICE_VEHICLE_CAR,
    name: language.label.car,
  },
  {
    id: SERVICE_VEHICLE_BICYCLE,
    name: language.label.bicycle,
  },
  // {
  //   id: SERVICE_VEHICLE_ELECTRIC_BICYCLE,
  //   name: language.label.electric_bike,
  // },
  // {
  //   id: SERVICE_VEHICLE_ELECTRIC_MOTORBIKE,
  //   name: language.label.electric_motorbike,
  // },
];

export const SERVICE_FORMULA_TYPE_ARRAY = [
  {
    id: SERVICE_FORMULA_TYPE_NUMBER_USE,
    name: language.label.permanent,
  },
  {
    id: SERVICE_FORMULA_TYPE_PERCENT,
    name: language.label.percent,
  },
  {
    id: SERVICE_FORMULA_TYPE_ACCUMULATED,
    name: language.label.cumulative,
  },
];

export const SERVICE_FORMULA_TYPE_SAVE_FORM_TO_ARRAY = [
  {
    id: SERVICE_FORMULA_TYPE_NUMBER_USE,
    name: language.label.usage_number,
  },
  {
    id: SERVICE_FORMULA_TYPE_PERCENT,
    name: language.label.percent,
  },
  {
    id: SERVICE_FORMULA_TYPE_ACCUMULATED,
    name: language.label.cumulative,
  },
];

export const SERVICE_FORMULA_TYPE_OTHER_ARRAY = [
  {
    id: SERVICE_FORMULA_TYPE_NUMBER_USE,
    name: language.label.usage_number,
  },
];

export const SERVICE_FORMULA_TYPE_NUMBER_LATCH_ARRAY = [
  {
    id: SERVICE_FORMULA_TYPE_ACCUMULATED,
    name: language.label.cumulative,
  },
  {
    id: SERVICE_FORMULA_TYPE_PERCENT,
    name: language.label.percent,
  },
];

export const SERVICE_FORMULA_TYPE_FIXED_ARRAY = [
  {
    id: SERVICE_FORMULA_TYPE_NUMBER_USE,
    name: language.label.permanent,
  },
];

export const SERVICE_FORMULA_TYPE_PARKING_ARRAY = [
  {
    id: SERVICE_FORMULA_TYPE_NUMBER_USE,
    name: language.label.permanent,
  },
  {
    id: SERVICE_FORMULA_TYPE_ACCUMULATED,
    name: language.label.cumulative,
  },
];

export const SERVICE_TYPE_OLD_ARRAY = [
  {
    id: SERVICE_TYPE_SAVE_FROM_TO,
    name: language.label.save_from_to,
  },
  {
    id: SERVICE_TYPE_SAVE_NUMBER_USE,
    name: language.label.save_number_use,
  },
  {
    id: SERVICE_TYPE_REGISTER_ONE_TIME,
    name: language.label.register_one_time,
  },
  {
    id: SERVICE_TYPE_REGISTER_LONG_TERM,
    name: language.label.register_long_term,
  },
];

export const SERVICE_TYPE_ARRAY = [
  {
    id: SERVICE_TYPE_ACCUMULATED,
    name: language.label.number_latch,
  },
  {
    id: SERVICE_TYPE_FIXED,
    name: language.label.permanent,
  },
  {
    id: SERVICE_TYPE_PARKING,
    name: language.label.parking,
  },
];

export const SURCHARGE_TYPES_ARRAY = [
  {
    id: PERCENT,
    name: language.label.percent,
  },
  {
    id: PRICE_MULTI_AMOUNT,
    name: language.price_multi_amount,
  },
];

export const PROMOTION_TYPES_ARRAY = [
  {
    id: PERCENT,
    name: language.label.percent,
  },
];

export const REQUIRE_INFO_TYPES_ARRAY = [
  {
    id: TEXT,
    name: language.label.text,
  },
  {
    id: NUMBER,
    name: language.label.number,
  },
  {
    id: DATE,
    name: language.label.date,
  },
];

export const SERVICE_TAX_ARRAY_MOCKUP = [
  {
    id: 1,
    name: 'VAT 10%',
  },
  {
    id: 2,
    name: 'VAT 20%',
  },
  {
    id: 3,
    name: 'VAT 30%',
  },
  {
    id: 4,
    name: 'VAT 40%',
  },
  {
    id: 5,
    name: 'VAT 50%',
  },
  {
    id: 6,
    name: 'VAT 60%',
  },
  {
    id: 7,
    name: 'VAT 70%',
  },
];

export const DATA_MOCKUP_EDIT = {
  name: 'TEN_DICH_VU',
  code: 'MA_DICH_VU',
  measureUnit: 'DON_VI',
  type: 'PARKING',
  icon: 'http://cyfeer-upload-file.s3-website.us-east-2.amazonaws.com/1599560135230-wallpaper2you_235820.jpg',
  description: 'MO_TA',
  tax: [1, 2],
  formulas: [
    {
      formula: [
        { name: 'MOTORBIKE', to: 1, price: 20000 },
        { name: 'MOTORBIKE', to: 3, price: 30000 },
        { name: 'MOTORBIKE', to: 5, price: 50000 },
      ],
      formulaType: 'ACCUMULATED',
    },
  ],
  accountRevenue: 2,
  accountReceiveCustomer: 1,
};

export const DATA_INITIAL_ADD = {
  serviceType: SERVICE_TYPE_SAVE_FROM_TO,
  status: true,
  isSaveNumberUseInMonth: false,
  feePeriodType: PREVIOUS_MONTH,
};

export const FEE_PERIOD_TYPE_ARRAY = [
  {
    id: PREVIOUS_MONTH,
    name: language.before_period,
  },
  {
    id: CURRENT_MONTH,
    name: language.in_the_period,
  },
  {
    id: NEXT_MONTH,
    name: language.after_period,
  },
];
