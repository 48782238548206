import { combineReducers } from 'redux';
import { PAYMENT_INIT, PAYMENT_BILL_GETS, PAYMENT_BILL_SUM_PAYMENT_GETS, PAYMENT_RECEIPT_CREATE, PAYMENT_UNIT_GETS } from './payment.types';

export const initState = {
  paymentInit: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
  },
  bills: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
    totalRecord: 0,
    data: [],
  },
  sumPayment: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
    data: 0,
  },
  receiptCreate: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  units: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
    data: [],
    totalRecord: 0,
  },
};

export default combineReducers({
  paymentInit: (state = initState.paymentInit, action) => {
    switch (action.type) {
      case PAYMENT_INIT.REQUEST:
        return {
          code: undefined,
          codeLanguage: undefined,
          isFetching: true,
        };
      case PAYMENT_INIT.SUCCESS:
      case PAYMENT_INIT.FAIL:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      case PAYMENT_INIT.REFRESH:
        return {
          ...initState.paymentInit,
        };
      default:
        return state;
    }
  },
  bills: (state = initState.bills, action) => {
    switch (action.type) {
      case PAYMENT_BILL_GETS.REQUEST:
        return {
          code: undefined,
          codeLanguage: undefined,
          isFetching: true,
          data: [],
          totalRecord: 0,
        };
      case PAYMENT_BILL_GETS.SUCCESS:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.data,
          totalRecord: action.payload.totalRecord,
        };
      case PAYMENT_BILL_GETS.FAIL:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: [],
          totalRecord: 0,
        };
      case PAYMENT_BILL_GETS.REFRESH:
        return {
          ...initState.bills,
        };
      default:
        return state;
    }
  },
  sumPayment: (state = initState.sumPayment, action) => {
    switch (action.type) {
      case PAYMENT_BILL_SUM_PAYMENT_GETS.REQUEST:
        return {
          code: undefined,
          codeLanguage: undefined,
          isFetching: true,
          data: 0,
        };
      case PAYMENT_BILL_SUM_PAYMENT_GETS.SUCCESS:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.data,
        };
      case PAYMENT_BILL_SUM_PAYMENT_GETS.FAIL:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: 0,
        };
      case PAYMENT_BILL_SUM_PAYMENT_GETS.REFRESH:
        return {
          ...initState.sumPayment,
        };
      default:
        return state;
    }
  },
  receiptCreate: (state = initState, action) => {
    switch (action.type) {
      case PAYMENT_RECEIPT_CREATE.REQUEST:
        return {
          code: undefined,
          codeLanguage: undefined,
          isFetching: true,
          data: undefined,
        };
      case PAYMENT_RECEIPT_CREATE.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isFetching: false,
          data: action.payload.data,
        };
      case PAYMENT_RECEIPT_CREATE.FAIL:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      case PAYMENT_RECEIPT_CREATE.REFRESH:
        return {
          ...initState.receiptCreate,
        };
      default:
        return state;
    }
  },
  units: (state = initState.units, action) => {
    switch (action.type) {
      case PAYMENT_UNIT_GETS.REQUEST:
        return {
          code: undefined,
          codeLanguage: undefined,
          isFetching: true,
          data: [],
          totalRecord: 0,
        };
      case PAYMENT_UNIT_GETS.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isFetching: false,
          data: action.payload.data,
          totalRecord: action.payload.totalRecord,
        };
      case PAYMENT_UNIT_GETS.FAIL:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: [],
          totalRecord: 0,
        };
      case PAYMENT_UNIT_GETS.REFRESH:
        return {
          ...initState.units,
        };
      default:
        return state;
    }
  },
});
