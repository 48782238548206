/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { threeDigits } from 'shared/utils';
import Title from './Title';
import Paragraph from './Paragraph';
import Text from './Text';

// eslint-disable-next-line react/prop-types
Typography.NegativeNumber = ({ children }) => {
  if (!Number.isNaN(+children)) {
    return children < 0 ? (
      <span
        css={(theme) => ({
          color: theme.color.primary,
        })}
      >
        ({threeDigits(Math.abs(children))})
      </span>
    ) : (
      threeDigits(children)
    );
  }
  return children;
};
Typography.Title = Title;
Typography.Paragraph = Paragraph;
Typography.Text = Text;

const LabelTransparent = styled.label`
  color: transparent;
`;

export { LabelTransparent };

export default Typography;
